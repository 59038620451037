export enum Cookie {
  AUTH_TOKEN = 'auth_token',
  PRIVACY_CONFIRMED = 'privacy_confirmed',
  COMPANY_ID = 'company_id',
  USER_NAME = 'user_name',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  USER_EMAIL = 'user_email',
  USER_ID = 'user_id',
  CART = 'cart',
  NAVIGATION_HISTORY = 'navigation_history',
  USER_CITY = 'user_city',
  USER_ROLE = 'user_role',
  TOKEN_EXPIRATION = 'token_expiration',
  REFRESH_TOKEN = 'refresh_token',
  SERVICE_ENABLED = 'service_enabled',
  OIL_DELIVERY_ENABLED = 'oil_delivery_enabled'
}

export enum DayStatus {
  Unknown = 0,
  Closed = 1,
  ManualClosed = 2,
  AutoOpened = 3,
  ManualOpened = 4
}

export enum DTOState {
  Unchanged = 0,
  Added = 1,
  Modified = 2,
  Deleted = 3
}

export enum PaymentState {
  Unknown = 0,
  PaymentPending = 1,
  PaymentFailed = 2,
  PaymentSuccess = 3
}

export enum OrderStatus {
  Unknown = 0,
  Temporary = 1,
  Pending = 2,
  Scheduled = 3,
  Delivered = 4,
  Canceled = 5,
  DeliveryFailed = 6,
  PaymentFailed = 7,
  Giveup = 8
}

export enum ServicesType {
  Unknown = 0,
  TuneUp = 1,
  ServiceCall = 2,
  OneYearContract = 3,
  OilDelivery = 4,
  All = 5
}
