import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { Cookie } from '../models/enums';
import { CookieService } from '@monorepo/utils';

@Injectable({
  providedIn: 'root'
})

export class CookieHelper {

  constructor(
    private cookieService: CookieService,
  ) { }

  getAuthToken() {
    return this.getCookie(Cookie.AUTH_TOKEN);
  }

  getUserRole() {
    return this.getCookie(Cookie.USER_ROLE);
  }

  getCompanyID() {
    return this.getCookie(Cookie.COMPANY_ID);
  }

  getUserID() {
    return this.getCookie(Cookie.USER_ID);
  }

  getUserEmail() {
    return this.getCookie(Cookie.USER_EMAIL);
  }

  verifyAuthToken(token: string): boolean {
    try {
      let decoded: any = jwtDecode(token);
      if (decoded.hasOwnProperty("CompanyId")) {
        return true
      }
      return false
    } catch (error) {
      return false
    }
  }

  setCookie(name: string, value: string) {
    return this.cookieService.set(name, value)
  }

  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  clearCookie() {
    return this.cookieService.deleteAll();
  }

  clearCookieByDomain(domain?: string, path: string = '/') {
    return this.cookieService.deleteAll(domain, path);
  }
}
